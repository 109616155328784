<template>
    <div class="container1150">
        <header>
            <strong>厦门卫视采访</strong>
        </header>
        <main>
            <div class="img1"></div>
            <a href="http://tv.xmtv.cn/2015/09/28/VIDE1443449007958146.shtml"
                >厦门卫视采访</a
            >
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            // width: 100%;
            width: 500px;
            height: 350px;
            margin: 10px auto;
            background-image: url("/img/news/news3-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        a {
            display: block;
            width: 1100px;
            height: 30px;
            font-size: 20px;
            margin: 0 auto;
            line-height: 30px;
            text-align: center;
        }
    }
}
</style>